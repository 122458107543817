<template>
  <table class="tableStyle">
          <thead>
            <tr>
              <th>功能描述</th>
              <th>
                <p>通用免费版</p>
                <p>免费</p>
              </th>
              <th>
                <p>VIP版</p>
                <p style="margin-bottom:10px;">￥500-1500/年</p>
                <a-button style="margin-bottom:10px;" type="primary" @click="showBuyFn">购买/续费</a-button>
              </th>
              <th>
                <p>定制VIP版</p>
                <p style="margin-bottom:10px;">请咨询客服</p>
                <a-button style="margin-bottom:10px;" type="primary" @click="showServiceFn">联系客服</a-button>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>部门设置</td>
              <td>
                <a-icon type="check" style="color:#51b52f" />
              </td>
              <td>
                <a-icon type="check" style="color:#51b52f" />
              </td>
              <td>
                <a-icon type="check" style="color:#51b52f" />
              </td>
            </tr>
            <tr>
              <td>职务设置</td>
              <td>
                <a-icon type="check" style="color:#51b52f" />
              </td>
              <td>
                <a-icon type="check" style="color:#51b52f" />
              </td>
              <td>
                <a-icon type="check" style="color:#51b52f" />
              </td>
            </tr>
            <tr>
              <td>人员信息管理</td>
              <td>
                <a-icon type="check" style="color:#51b52f" />
              </td>
              <td>
                <a-icon type="check" style="color:#51b52f" />
              </td>
              <td>
                <a-icon type="check" style="color:#51b52f" />
              </td>
            </tr>
            <tr>
              <td>免审核名单</td>
              <td>
                <a-icon type="close" />
              </td>
              <td>
                <a-icon type="check" style="color:#51b52f" />
              </td>
              <td>
                <a-icon type="check" style="color:#51b52f" />
              </td>
            </tr>
            <tr>
              <td>添加用餐人员</td>
              <td>
                <a-icon type="check" style="color:#51b52f" />
              </td>
              <td>
                <a-icon type="check" style="color:#51b52f" />
              </td>
              <td>
                <a-icon type="check" style="color:#51b52f" />
              </td>
            </tr>
            <tr>
              <td>菜品库</td>
              <td>
                <a-icon type="check" style="color:#51b52f" />
              </td>
              <td>
                <a-icon type="check" style="color:#51b52f" />
              </td>
              <td>
                <a-icon type="check" style="color:#51b52f" />
              </td>
            </tr>
            <tr>
              <td>每日菜谱</td>
              <td>
                <a-icon type="check" style="color:#51b52f" />
              </td>
              <td>
                <a-icon type="check" style="color:#51b52f" />
              </td>
              <td>
                <a-icon type="check" style="color:#51b52f" />
              </td>
            </tr>
            <tr>
              <td>用餐日期</td>
              <td>
                <a-icon type="check" style="color:#51b52f" />
              </td>
              <td>
                <a-icon type="check" style="color:#51b52f" />
              </td>
              <td>
                <a-icon type="check" style="color:#51b52f" />
              </td>
            </tr>
            <tr>
              <td>开餐日期</td>
              <td>
                <a-icon type="check" style="color:#51b52f" />
              </td>
              <td>
                <a-icon type="check" style="color:#51b52f" />
              </td>
              <td>
                <a-icon type="check" style="color:#51b52f" />
              </td>
            </tr>
            <tr>
              <td>报餐明细</td>
              <td>
                <a-icon type="check" style="color:#51b52f" />
              </td>
              <td>
                <a-icon type="check" style="color:#51b52f" />
              </td>
              <td>
                <a-icon type="check" style="color:#51b52f" />
              </td>
            </tr>
            <tr>
              <td>报餐统计</td>
              <td>
                <a-icon type="check" style="color:#51b52f" />
              </td>
              <td>
                <a-icon type="check" style="color:#51b52f" />
              </td>
              <td>
                <a-icon type="check" style="color:#51b52f" />
              </td>
            </tr>
            <tr>
              <td>小卖部商品管理</td>
              <td>
                <a-icon type="close"/>
              </td>
              <td>
                <a-icon type="check" style="color:#51b52f" />
              </td>
              <td>
                <a-icon type="check" style="color:#51b52f" />
              </td>
            </tr>
            <tr>
              <td>小卖部订单管理</td>
              <td>
                <a-icon type="close" />
              </td>
              <td>
                <a-icon type="check" style="color:#51b52f" />
              </td>
              <td>
                <a-icon type="check" style="color:#51b52f" />
              </td>
            </tr>
            <tr>
              <td>消费二维码</td>
              <td>
                <a-icon type="close"/>
              </td>
              <td>
                <a-icon type="check" style="color:#51b52f" />
              </td>
              <td>
                <a-icon type="check" style="color:#51b52f" />
              </td>
            </tr>
            <tr>
              <td>账户充值</td>
              <td>
                <a-icon type="close" />
              </td>
              <td>
                <a-icon type="check" style="color:#51b52f" />
              </td>
              <td>
                <a-icon type="check" style="color:#51b52f" />
              </td>
            </tr>
            <tr>
              <td>账户明细</td>
              <td>
                <a-icon type="close" />
              </td>
              <td>
                <a-icon type="check" style="color:#51b52f" />
              </td>
              <td>
                <a-icon type="check" style="color:#51b52f" />
              </td>
            </tr>
            <tr>
              <td>公告管理</td>
              <td>
                <a-icon type="close" />
              </td>
              <td>
                <a-icon type="check" style="color:#51b52f" />
              </td>
              <td>
                <a-icon type="check" style="color:#51b52f" />
              </td>
            </tr>
            <tr>
              <td>菜谱图片上传</td>
              <td>
                <a-icon type="check" style="color:#51b52f" />
              </td>
              <td>
                <a-icon type="check" style="color:#51b52f" />
              </td>
              <td>
                <a-icon type="check" style="color:#51b52f" />
              </td>
            </tr>
            <tr>
              <td>首页幻灯片</td>
              <td>
                <a-icon type="close" />
              </td>
              <td>
                <a-icon type="check" style="color:#51b52f" />
              </td>
              <td>
                <a-icon type="check" style="color:#51b52f" />
              </td>
            </tr>
            <tr>
              <td>设备连接</td>
              <td>
                <a-icon type="close" />
              </td>
              <td>
                <a-icon type="check" style="color:#51b52f" />
              </td>
              <td>
                <a-icon type="check" style="color:#51b52f" />
              </td>
            </tr>
          </tbody>
        </table>
</template>

<script>
export default {
    name:'VersionTable',
  data(){
    return {}
  },
  computed:{},
  components: {},
  created(){},
  mounted(){},
  methods: {
      showBuyFn(){
         this.$emit("showBuyFn") 
      },
      showServiceFn(){
          this.$emit("showServiceFn") 
      }
  }
}
</script>
<style lang='less' scoped>
.tableStyle {
      width: 100%;
      // border: 1px solid #d9d9d9;
      border-spacing: 0;
      border-collapse: collapse;
      tr {
        border: 1px solid #d9d9d9;
        border-left: 0;
        border-right: 0;
        td {
          border: 1px solid #d9d9d9;
          border-left: 0;
          // border-right: 0;
        }
      }
    }

</style>
